.main-content{
    display: flex; 
    height: 100%;
    min-height: calc(100vh - 82px);
    border-top: 1px solid #9f9f9f;
}
.content-section{
    margin: 0.5rem;
    width: 100%;
}
.primary-color{
  color: #11678D;
}
.secondary-color{
  color: #198754;
}
.primary-colors{
    background-color: #11678D;
    color: #fff;
}
.btn.primary-colors:hover{
  background-color: #198754;
}
.form-control.is-valid,
.was-validated .form-control:valid + .input-group-text {
  border-color: #198754;
}
.form-control.is-invalid,
.was-validated .form-control:invalid + .input-group-text {
  border-color: #dc3545;
}
.error{
  color: #dc3545;
}
#dashboard_filter input, #mydpc_filter input{
  width: 426px !important;
}