.forgot-password {
  color: #fff;
  text-decoration: none;
}
.forgot-password:hover{
  color: #1B5E2F;
}
#login-form .input-heigt, #change-password-form .input-heigt, #forgot_password_form .input-heigt{
  min-height: 3.5rem;
  font-size: 1rem;
}
.btn-size {
  padding: 0.85rem;
  font-size: 1rem;
}

#login-form .input-group-text {
  background-color: #fff;
  border-left: none;
}
#login-form [name="password"] {
  border-right: none;
}
.login-logo{
  height: 6rem;
}