.dataTables_paginate, .dataTables_filter{
    float: right;
}
thead input {
    width: 100%;
}
table{
    width: 100% !important;
    /* font-size: 0.75rem; */
}
.excelexp{
    float: right;
}
.mydpc-title{
    font-weight: 600;
    padding-top: 7px;
}

#mydpc .hugediff td{
    background-color: rgb(250, 95, 95) !important;
  }