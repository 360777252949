.leftMenuTogggle {
  padding: 1.4rem;
  position: absolute;
  top: 0;
  right: 4rem;
  z-index: 99;
  cursor: pointer;
}
a.ps-menu-button:hover {
  color: #000;
}
.ps-menuitem-root.active {
  background-color: #f7f7f7;
}
.ps-menuitem-root.active a {
  color: #000;
}
.ps-sidebar-root {
  min-height: 100vh;
}
.ps-sidebar-container {
  position: fixed !important;
  width: inherit;
}
.css-17pxsjb {
  background-image: url("../Assets/wassan_wta_bg.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

/* .ps-menu-button[title]:hover::after {
  content: attr(title);
  background-color: #116a64;
  color: #fff;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 0;
  white-space: nowrap;
  z-index: 99999;
} */
