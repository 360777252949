.logo{
    width: 4rem;
}
.text-right{
    text-align: right;
}

.dropdown-toggle::after{
    display: none;
}

.profilepic{
    width: 56px;
    min-width: 56px;
    height: 56px;
    border-radius: 50% !important;
}
#user-menu{
    border: none;
    margin: 0;
    padding: 0;
}

.dropdown-menu ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.dropdown-menu ul li{
    margin: 0;
    padding: 0.2rem 0;
}
.dropdown-menu a{
    text-decoration: none;
    color:#000;
    display: inline-block;
    width: 100%;
}
.dropdown-menu a:hover{
    color: #fff;
}
.dropdown-menu ul li:hover{
    background-color: #198754;
}

.dropdown-item-text{
    width: max-content;
    line-height: 2rem;
}