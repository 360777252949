.pdfdata table img{
    width: 56px;
}
.pdfdata table{
    width: 600px;
}
.pdfdata table tr td{
    width: 250px;
}
.pdfdata table tbody tr td{
    font-size: 6px;
}
.pdfdata table tbody tr td.title{
    text-align: right;
}
.pdfdata{
    margin: 20px;
}