.cardGroup {
  margin-top: 2rem;
}
.trip-table {
  font-size: 13px;
}
@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: 1px solid #dfdfdf;
    padding: 0em;
  }
}

.modal-dialog {
  --bs-modal-width: 90% !important;
}

.leaflet-popup-content {
  width: 426px !important;
}
.card-header a {
  text-decoration: none;
}

.card-header a:hover {
  text-decoration: underline;
  color: #fff;
}
.css-qbdosj-Input,
.css-3w2yfm-ValueContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.dataTables_paginate, .dataTables_filter{
  float: right;
}
thead input {
  width: 100%;
}
table{
  width: 100% !important;
  /* font-size: 0.75rem; */
}
#pdftable{
  width: auto !important;
  font-size: 7px;
}
.dashboard-title{
  font-size: 1rem;
  font-weight: 600;
}

#dashboard .hugediff td{
  background-color: rgb(250, 95, 95) !important;
}