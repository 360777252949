.update-profile {
  color: #fff;
  background-color: transparent;
  border: none;
  padding: 0;
}

table.info-table tr td:last-child {
  font-weight: 500;
}

table.info-table tr td:first-child {
  font-weight: 300;
}
.easy-edit-button-wrapper {
  margin-left: 0.1rem;
}
.easy-edit-button {
  background-color: #fff;
  margin: 0 0 0 0.5em;
  padding: 0.1rem;
}
table.info-table tr:hover .easy-edit-button {
  background-color: #ececec;
}

/* input:not(input[type="checkbox"]),
select {
  font-size: 1rem;
} */

.txtedit-wrapper {
  border: 1px solid #e5e5e5;
}
.update-editor-contet {
  visibility: hidden;
}
.txtedit-toolbar a{
    text-decoration: none;
    color: black;
}
.public-DraftStyleDefault-block{
  margin: 0 !important;
}
.profile table td{
  border: none;
} 
.profile table tr{
  border-bottom: 1px solid #f5f5f5;
} 
.mand{
  color: #ff0000;
}